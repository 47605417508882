(function($) {
	'use strict';
	window.sauzywebsite = {
		cache: {
			$document: $(document),
			$window: $(window)
		}, 
		init: function() {
			this.bindEvents();
		},
		bindEvents: function() { 
			var self = this;

			$(window).ready(function() {
				if($('body').hasClass('isdesktop')){
					
				}
				
				self.initCarousels();
				self.initMaps();
				self.initForms();
				
				$(window).resize(function() {
					self.handleResize();
				});
				
			});
			$('[data-toggle="tooltip"]').tooltip();
		},
		handleResize: function() {
			
		},
		initCarousels: function(){
			$('.carousel').carousel({'interval':false});
			console.log('carousels init');
		},
		initForms: function(){
			if($('body').hasClass('page-template-template_contact')){
				$(".caleran").caleran();
				$('#booking_area').show();
				$('select[name="champ_objet"]').on('change',function(e){
					var optionSelected = $("option:selected", this);
					var valueSelected = this.value;
					if(
						valueSelected==="Demande de réservation roulotte"
						|| valueSelected==="Demande de réservation camping"
					){
						$('#booking_area').show();
					}
					else{
						$('#booking_area').hide();
					}
				})
			}
			console.log('validate');
			jQuery("#mailchimpSignupForm").validate({ 
				submitHandler: function(form, event) {
					event.preventDefault();
					var formurl 	= jQuery('#mailchimpSignupForm').attr('action');
					var data 		= jQuery(form).serialize();
		
					jQuery.ajax({
			            url: "/wp-content/plugins/sauzy_mailchimpform/subscribe.php",
			            type: 'post',
			            data: jQuery(form).serialize(),
			            success: function(response) {
				            jQuery("#mailchimpSignupForm").slideUp();
			                jQuery('#mailchimpSignupResponse').html(response);
							}        
			        	});
			    	}
			    });
			
		},
		initMaps: function(){

			/* GLOBALS */
			var greenIcon = L.icon({
			    iconUrl: '/wp-content/themes/sauzy/static/img/marker_map_sauzy.png',
			    shadowUrl: '/wp-content/themes/sauzy/static/img/marker_map_sauzy_shadow.png',
			    iconSize:     [38, 95], // size of the icon
			    shadowSize:   [50, 64], // size of the shadow
			    iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
			    shadowAnchor: [4, 62],  // the same for the shadow
			    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
			});
			
			
			/* CONTACT MAP */
			if($('body').hasClass('page-template-template_contact')){
				var contactmap = L.map('contactmap', {
				    center: [44.821510,5.163823],
				    zoom: 13,
				    scrollWheelZoom: false
				});
				var layer_contactmap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 19,
					attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				});
				contactmap.addLayer(layer_contactmap);
				L.marker([44.821510,5.163823], {icon: greenIcon}).addTo(contactmap);
			}
			
			/* FOOTER MAP */
			var footermap = L.map('footermap', {
			    center: [44.821510,5.163823],
			    zoom: 13,
			    scrollWheelZoom: false
			});
			var layer_footermap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 19,
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			});
			footermap.addLayer(layer_footermap);
			L.marker([44.821510,5.163823], {icon: greenIcon}).addTo(footermap);
		}
	}
	sauzywebsite.init();
})(jQuery);
